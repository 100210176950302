"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributeValues = void 0;
var react_1 = require("react");
var measures_1 = require("../../../../../client/measures");
var values_1 = require("../../../../../client/values");
var store_1 = require("../../../../../../store");
var useAttributeValues = function (_a) {
    var attributeName = _a.attributeName, sortingItem = _a.sortingItem, isMeasure = _a.isMeasure, options = _a.options;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _b = (0, values_1.useGetTextAttributeAggregatedValues)({
        includeNA: true,
        catalogId: selectedCatalogId,
        productTreeNodeId: selectedStrategyNodeId,
        attributeName: attributeName,
        sortingItem: sortingItem,
        options: { skip: isMeasure || options.skip },
    }), textAttributeValues = _b.data, textAttributeValuesLoading = _b.isLoading, textAttributeValuesRefetching = _b.isRefetching, textAttributeValuesLoadingError = _b.error, _c = _b.pagination, moreTextAttributeValuesLoading = _c.isLoadingMore, textAttributeValuesHasNextPage = _c.hasNextPage, fetchMoreTextAttributeValues = _c.onNextPage;
    var _d = (0, measures_1.useGetAggregatedProductMeasureUnits)({
        catalogId: selectedCatalogId,
        productTreeNodeId: selectedStrategyNodeId,
        dimension: attributeName,
        sortingItem: sortingItem,
        options: { skip: !isMeasure || options.skip },
    }), measureUnits = _d.data, measureUnitsLoading = _d.isLoading, measureUnitsRefetching = _d.isRefetching, measureUnitsLoadingError = _d.error, _e = _d.pagination, measureUnitsHasNextPage = _e.hasNextPage, fetchMoreMeasureUnits = _e.onNextPage, moreMeasureUnitsLoading = _e.isLoadingMore;
    var values = (0, react_1.useMemo)(function () {
        var _a;
        if (!isMeasure) {
            return textAttributeValues !== null && textAttributeValues !== void 0 ? textAttributeValues : [];
        }
        var measureValues = (_a = measureUnits === null || measureUnits === void 0 ? void 0 : measureUnits.map(function (_a) {
            var _b;
            var _c = _a.node, productCount = _c.productCount, unit = _c.unit, values = _c.values;
            return ({
                productCount: productCount,
                value: unit,
                isMeasureUnit: true,
                nestedValues: (_b = values.edges) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
                    var _b = _a.node, value = _b.value, productCount = _b.productCount;
                    return ({ value: value, productCount: productCount });
                }),
            });
        })) !== null && _a !== void 0 ? _a : [];
        return measureValues;
    }, [isMeasure, measureUnits, textAttributeValues]);
    return {
        data: values,
        isLoading: textAttributeValuesLoading || measureUnitsLoading,
        isRefetching: textAttributeValuesRefetching || measureUnitsRefetching,
        error: textAttributeValuesLoadingError || measureUnitsLoadingError,
        pagination: {
            isLoadingMore: moreTextAttributeValuesLoading || moreMeasureUnitsLoading,
            onNextPage: !isMeasure ? fetchMoreTextAttributeValues : fetchMoreMeasureUnits,
            hasNextPage: textAttributeValuesHasNextPage || measureUnitsHasNextPage,
        },
    };
};
exports.useAttributeValues = useAttributeValues;
