"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseDataFromProductAttributeValuePairs = exports.mergeTextAndMeasureProductAttributeValuePairs = exports.mergeTextAndMeasureProductPresentationAttributes = void 0;
var attributes_1 = require("../../client/attributes");
var utils_1 = require("../../utils");
var mergeTextAndMeasureProductPresentationAttributes = function (productPresentationTextAttributes, productPresentationMeasureAttributes) {
    var _a, _b, _c;
    var textAttributes = (_b = (_a = productPresentationTextAttributes === null || productPresentationTextAttributes === void 0 ? void 0 : productPresentationTextAttributes.edges) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
        var node = _a.node;
        return node;
    })) !== null && _b !== void 0 ? _b : [];
    var measureAttributes = (_c = productPresentationMeasureAttributes === null || productPresentationMeasureAttributes === void 0 ? void 0 : productPresentationMeasureAttributes.edges.map(function (_a) {
        var _b = _a.node, dimension = _b.dimension, values = _b.values;
        return ({
            label: dimension,
            validValues: values,
            invalidValues: [],
            allowedValues: [], // all Strategy values for the attribute
        });
    })) !== null && _c !== void 0 ? _c : [];
    var presentationLabels = __spreadArray(__spreadArray([], __read(textAttributes), false), __read(measureAttributes), false);
    return (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Ascend)(presentationLabels, 'label');
};
exports.mergeTextAndMeasureProductPresentationAttributes = mergeTextAndMeasureProductPresentationAttributes;
var mergeTextAndMeasureProductAttributeValuePairs = function (productLabelValuePairs, productMeasureValuePairs) {
    var _a, _b;
    var productTextAttributeValuePairs = (_a = productLabelValuePairs === null || productLabelValuePairs === void 0 ? void 0 : productLabelValuePairs.edges.reduce(function (acc, _a) {
        var _b = _a.node, label = _b.label, value = _b.value, origin = _b.origin, assertedIncorrect = _b.assertedIncorrect;
        return (assertedIncorrect ? acc : __spreadArray(__spreadArray([], __read(acc), false), [{ label: label, value: value, origin: origin }], false));
    }, [])) !== null && _a !== void 0 ? _a : [];
    var productMeasureAttributeValuePairs = (_b = productMeasureValuePairs === null || productMeasureValuePairs === void 0 ? void 0 : productMeasureValuePairs.edges.map(function (_a) {
        var _b = _a.node, dimension = _b.dimension, value = _b.value, unit = _b.unit, origin = _b.origin;
        return ({
            label: dimension,
            value: value,
            unit: unit,
            origin: origin,
        });
    })) !== null && _b !== void 0 ? _b : [];
    var mergedProductAttributeValuePairs = __spreadArray(__spreadArray([], __read(productTextAttributeValuePairs), false), __read(productMeasureAttributeValuePairs), false);
    return (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Ascend)(mergedProductAttributeValuePairs, 'label');
};
exports.mergeTextAndMeasureProductAttributeValuePairs = mergeTextAndMeasureProductAttributeValuePairs;
var baseLabelNames = [
    attributes_1.PRODUCT_BRAND_LABEL,
    attributes_1.PRODUCT_DESCRIPTION_LABEL,
    attributes_1.PRODUCT_PAGE_URL_LABEL,
    attributes_1.PRODUCT_IMAGE_LABEL,
    attributes_1.PRODUCT_CATEGORIES_LABEL,
];
var getBaseDataFromProductAttributeValuePairs = function (product, productAttributeValuePairs) {
    var _a;
    if (!product || !productAttributeValuePairs.length) {
        return {};
    }
    var name = product.name, createdAt = product.createdAt, categories = product.categories, externalId = product.externalId;
    var baseProductData = {
        createdAt: createdAt,
        name: name,
        externalId: externalId,
        classificationProductType: (_a = categories === null || categories === void 0 ? void 0 : categories.edges[0]) === null || _a === void 0 ? void 0 : _a.node.category,
    };
    baseLabelNames.forEach(function (item) {
        var _a;
        var label = productAttributeValuePairs.find(function (_a) {
            var label = _a.label;
            return label === item;
        });
        baseProductData[item] = String((_a = label === null || label === void 0 ? void 0 : label.value) !== null && _a !== void 0 ? _a : '-');
    });
    return baseProductData;
};
exports.getBaseDataFromProductAttributeValuePairs = getBaseDataFromProductAttributeValuePairs;
