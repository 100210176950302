"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetMissingAttributes = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var ramda_1 = require("ramda");
var use_get_strategy_attributes_hook_1 = require("./use-get-strategy-attributes.hook");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var get_tree_node_validation_labels_query_1 = require("../get-tree-node-validation-labels.query");
var models_1 = require("../../../models");
var useGetMissingAttributes = function (_a) {
    var productTreeRootNodeId = _a.productTreeRootNodeId, productTreeNodeId = _a.productTreeNodeId, options = _a.options;
    var skip = !productTreeRootNodeId || !productTreeNodeId || (options === null || options === void 0 ? void 0 : options.skip);
    var _b = (0, use_get_strategy_attributes_hook_1.useGetStrategyAttributes)({
        productTreeNodeId: productTreeRootNodeId,
        sortingItem: models_1.SortingItem.SortByTextAsc,
        options: { skip: skip },
    }), attributesData = _b.data, previousAttributesData = _b.previousData, attributesLoading = _b.isLoading, attributesError = _b.error, _c = _b.pagination, attributesMoreItemsLoading = _c.isLoadingMore, pageNumber = _c.pageNumber, onNextPage = _c.onNextPage, hasNextPage = _c.hasNextPage, refetch = _b.refetch;
    /*
      TODO: Can be pagination here or does the whole list needs to be loaded at once?
      logic: need to get all attributes in the current node to exclude them from the whole list of attributes in all nodes
    */
    var _d = (0, client_1.useQuery)(get_tree_node_validation_labels_query_1.GET_TREE_NODE_VALIDATION_LABELS, { variables: { productTreeNodeId: productTreeNodeId }, skip: skip }), treeNodeValidatorsData = _d.data, treeNodeValidatorsLoading = _d.loading, treeNodeValidatorsError = _d.error;
    var data = attributesLoading && (!attributesData || (0, ramda_1.isEmpty)(attributesData)) ? previousAttributesData : attributesData;
    var attributeOptionList = (0, react_1.useMemo)(function () {
        var _a;
        if (!data) {
            return [];
        }
        var existingAttributeList = (_a = treeNodeValidatorsData === null || treeNodeValidatorsData === void 0 ? void 0 : treeNodeValidatorsData.productTreeNode.labelValidators.map(function (item) { return item === null || item === void 0 ? void 0 : item.label; })) !== null && _a !== void 0 ? _a : [];
        var existingAttributes = new Set(existingAttributeList);
        var uniqueAttributes = data
            .filter(function (_a) {
            var label = _a.node.label;
            return !existingAttributes.has(label);
        })
            .map(function (_a) {
            var _b = _a.node, label = _b.label, description = _b.description;
            return ({ label: label, value: label, description: description });
        });
        var currentLastItemNumber = client_constants_1.PAGE_SIZE_LARGE * pageNumber;
        return uniqueAttributes.slice(0, currentLastItemNumber);
    }, [data, treeNodeValidatorsData, pageNumber]);
    var onRefetch = (0, react_1.useCallback)(function (searchString) { return refetch({
        filter: {
            some: {
                label: {
                    contains: (0, client_utils_1.normalizeSearchString)(searchString),
                },
            },
        },
    }); }, [refetch]);
    return {
        attributeOptionList: attributeOptionList,
        hasNextPage: hasNextPage,
        loading: attributesLoading || treeNodeValidatorsLoading,
        moreItemsLoading: attributesMoreItemsLoading,
        error: attributesError || treeNodeValidatorsError,
        fetchMore: onNextPage,
        refetch: onRefetch,
    };
};
exports.useGetMissingAttributes = useGetMissingAttributes;
