"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENRICH = exports.EnrichSection = void 0;
// eslint-disable-next-line import/prefer-default-export
var EnrichSection;
(function (EnrichSection) {
    EnrichSection["Curation"] = "curation";
    EnrichSection["Dashboards"] = "dashboards";
    EnrichSection["Libraries"] = "libraries";
    EnrichSection["MyWork"] = "mywork";
    EnrichSection["Notifications"] = "notifications";
    EnrichSection["ProductEditor"] = "producteditor";
    EnrichSection["Strategy"] = "strategy";
    EnrichSection["EnrichAI"] = "enrichai";
    EnrichSection["StrategyMatrix"] = "strategymatrix";
})(EnrichSection = exports.EnrichSection || (exports.EnrichSection = {}));
exports.ENRICH = 'enrich';
