"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProposalPresentationAttributesFilter = exports.createPresentationAttributesFilterFromSelectedAttributeValue = exports.createFilterFromAttributeNode = void 0;
var graphql_1 = require("../../../../__generated__/graphql");
var attributes_1 = require("../../attributes");
var createFilterFromAttributeNode = function (_a) {
    var _b, _c, _d;
    var label = _a.label, value = _a.value, unit = _a.unit;
    var isUnknownValue = value === attributes_1.SpecialAttributeValue.Unknown;
    var filterKey = unit ? 'measures' : 'labels';
    var labelFilterKey = unit ? 'dimension' : 'label';
    var valueFilterKey = unit ? 'unit' : 'value';
    return [
        (_b = {},
            _b[filterKey] = __assign({}, isUnknownValue ? {
                none: (_c = {},
                    _c[labelFilterKey] = {
                        equals: label,
                    },
                    _c),
            } : {
                some: __assign((_d = {}, _d[labelFilterKey] = {
                    equals: label,
                }, _d[valueFilterKey] = {
                    equals: unit || value,
                }, _d), unit && value ? {
                    value: {
                        gte: value,
                        lte: value,
                    },
                } : null),
            }),
            _b),
    ];
};
exports.createFilterFromAttributeNode = createFilterFromAttributeNode;
// Temporary solution. Remove this when BE data model is changed (status field is required)
var createPresentationAttributesFilterFromSelectedAttributeValue = function (_a) {
    var label = _a.label, value = _a.value;
    var isUnknownValue = value === attributes_1.SpecialAttributeValue.Unknown;
    if (!isUnknownValue) {
        return null;
    }
    return [
        {
            labels: {
                some: {
                    label: {
                        equals: label,
                    },
                    error: {
                        in: [graphql_1.ProductPresentationLabelErrorType.InvalidValue, graphql_1.ProductPresentationLabelErrorType.MissingValue],
                    },
                },
            },
        },
    ];
};
exports.createPresentationAttributesFilterFromSelectedAttributeValue = createPresentationAttributesFilterFromSelectedAttributeValue;
// Temporary solution. Remove this when BE data model is changed (status field is required)
var createProposalPresentationAttributesFilter = function (_a) {
    var label = _a.label, value = _a.value;
    var isUnknownValue = value === attributes_1.SpecialAttributeValue.Unknown;
    if (!isUnknownValue) {
        return null;
    }
    return {
        some: {
            labels: {
                some: {
                    label: {
                        equals: label,
                    },
                    error: {
                        in: [graphql_1.ProductPresentationLabelErrorType.InvalidValue, graphql_1.ProductPresentationLabelErrorType.MissingValue],
                    },
                },
            },
        },
    };
};
exports.createProposalPresentationAttributesFilter = createProposalPresentationAttributesFilter;
