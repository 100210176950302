"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyMatrix = void 0;
var react_1 = __importStar(require("react"));
var hooks_1 = require("../client/hooks");
var attributes_matrix_selector_container_1 = require("../components/attributes-matrix-selector/attributes-matrix-selector.container");
var matrix_table_component_1 = require("../components/matrix-table.component");
var table_styles_1 = require("../components/table.styles");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var StrategyMatrix = function () {
    var _a, _b, _c, _d;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeRootNodeId = (0, store_1.useProductTreeRootNodeId)();
    var selectedProductTreeId = (0, store_1.useProductTreeId)();
    var _e = __read((0, react_1.useState)(null), 2), selectedAttribute = _e[0], setSelectedAttribute = _e[1];
    // const [selectedL1Category, setSelectedL1Category] = useState<string[]>(null);
    var data = (0, hooks_1.useGetDescendantsForStrategyMatrix)({
        productTreeNodeId: productTreeRootNodeId,
    }).data;
    var totalCount = (_b = (_a = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _a === void 0 ? void 0 : _a.descendants) === null || _b === void 0 ? void 0 : _b.totalCount;
    var allProductTreeNodes = (_d = (_c = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _c === void 0 ? void 0 : _c.descendants) === null || _d === void 0 ? void 0 : _d.edges;
    var handleSelectAttribute = function (selected) {
        setSelectedAttribute(selected);
    };
    // const handleSelectL1Category = (selected: string[]) => {
    //   setSelectedL1Category(selected);
    // };
    return (react_1.default.createElement(table_styles_1.StyledMatrixContainer, null,
        react_1.default.createElement(table_styles_1.StyledPageTitle, { className: "title", title: t({ key: 'STRATEGY_MATRIX' }) }),
        react_1.default.createElement(attributes_matrix_selector_container_1.AttributeSelect, { onSelect: handleSelectAttribute }),
        react_1.default.createElement(matrix_table_component_1.MatrixTable, { selectedProductTreeId: selectedProductTreeId, selectedCatalogId: selectedCatalogId, selectedStrategyNodeId: productTreeRootNodeId, selectedAttribute: selectedAttribute, totalCount: totalCount, allProductTreeNodes: allProductTreeNodes })));
};
exports.StrategyMatrix = StrategyMatrix;
exports.default = exports.StrategyMatrix;
