"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProductAttributesValuesForStrategyMatrix = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetProductAttributesValuesForStrategyMatrix = function (_a) {
    var ids = _a.ids, productTreeNodeId = _a.productTreeNodeId, attribute = _a.attribute, productTreeId = _a.productTreeId;
    var _b = (0, client_1.useQuery)(gql_1.GET_STRATEGY_VALUES_DISTRIBUTION, {
        variables: {
            productTreeNodeId: productTreeNodeId,
            ids: ids,
            attribute: attribute,
            productTreeId: productTreeId,
            first: 1900, // will add infinity scroll in the future, under this ticket EN-4095
        },
        skip: !productTreeNodeId || !ids || !attribute || !productTreeId,
    }), data = _b.data, error = _b.error, networkStatus = _b.networkStatus;
    var parsedData = (0, react_1.useMemo)(function () {
        var _a;
        if (!((_a = data === null || data === void 0 ? void 0 : data.catalogs) === null || _a === void 0 ? void 0 : _a.edges))
            return [];
        return data.catalogs.edges.flatMap(function (catalog) {
            var _a, _b;
            var statistics = ((_b = (_a = catalog.node.statistics) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.edges) || [];
            return statistics.map(function (stat) {
                var node = stat.node;
                var dimensions = node.dimensions;
                var product = dimensions.productTreeNode;
                return {
                    productId: product.id,
                    productTitle: product.title,
                    attribute: dimensions.attribute,
                    value: dimensions.value,
                    applied: node.applied,
                };
            });
        });
    }, [data]);
    return {
        data: parsedData,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        error: error === null || error === void 0 ? void 0 : error.message,
    };
};
exports.useGetProductAttributesValuesForStrategyMatrix = useGetProductAttributesValuesForStrategyMatrix;
