"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProducts = void 0;
var react_1 = require("react");
var hooks_1 = require("../products-preview/products-list-view/hooks");
var product_1 = require("../../../client/product");
var feature_flags_1 = require("../../../../feature-flags");
var hooks_2 = require("../../../../hooks");
var store_1 = require("../../../../store");
var useProducts = function () {
    var featureFlagsService = (0, hooks_2.useFeatureFlagsService)().featureFlagsService;
    var isMeasureEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures);
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    var searchTerm = (0, store_1.useSearch)();
    var isListViewMode = (0, store_1.useIsListViewMode)();
    // Have to get table config regardless of active view mode
    var _a = (0, hooks_1.useProductsTableConfig)().tableColumns.selection, excludedLabelColumns = _a.excludedLabelColumns, excludedMeasureColumns = _a.excludedMeasureColumns, selectAllMode = _a.selectAllMode;
    var productLabelFilter = (0, react_1.useMemo)(function () {
        if (!excludedLabelColumns.length) {
            return undefined;
        }
        return { label: (!selectAllMode ? { in: excludedLabelColumns } : { notIn: excludedLabelColumns }) };
    }, [excludedLabelColumns, selectAllMode]);
    var productMeasureFilter = (0, react_1.useMemo)(function () {
        if (!excludedMeasureColumns.length) {
            return undefined;
        }
        return { dimension: (!selectAllMode ? { in: excludedMeasureColumns } : { notIn: excludedMeasureColumns }) };
    }, [excludedMeasureColumns, selectAllMode]);
    // Get actual products
    var productsResponse = (0, product_1.useGetProducts)(__assign({ attributeNode: selectedAttributeNode, search: searchTerm, skipMeasures: !isMeasureEnabled }, (isListViewMode ? {
        productLabelFilter: productLabelFilter,
        productMeasureFilter: productMeasureFilter,
    } : {})));
    return productsResponse;
};
exports.useProducts = useProducts;
