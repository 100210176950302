"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserRoutePermissions = void 0;
var routes_1 = require("./routes");
var feature_flags_1 = require("../feature-flags");
var hooks_1 = require("../hooks");
var State = __importStar(require("../store/state"));
// eslint-disable-next-line import/prefer-default-export
var useUserRoutePermissions = function () {
    var client = (0, hooks_1.useEnrichClient)();
    var getRoleFromRoles = function (roles) { return (roles.includes(State.UserRole.Admin)
        ? State.UserRole.Admin
        : roles.includes(State.UserRole.Merchandiser)
            ? State.UserRole.Merchandiser
            : null); };
    var getUserRole = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, roles, licensed;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, client.identity.getMyIdentity()];
                case 1:
                    _a = _b.sent(), roles = _a.roles, licensed = _a.licensed;
                    if (licensed)
                        return [2 /*return*/, getRoleFromRoles(roles)];
                    return [2 /*return*/, State.UserRole.Unlicensed];
            }
        });
    }); };
    var getDrawerRoutes = function (featureFlagsService) { return __awaiter(void 0, void 0, void 0, function () {
        var isEnrichAIEnabled, enrichDrawerRoutes, routeAllowList, _a, roles, licensed, role, e_1;
        var _b, _c;
        var _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    isEnrichAIEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.EnrichAI);
                    enrichDrawerRoutes = (0, routes_1.getEnrichDrawerRoutes)();
                    routeAllowList = (_b = {},
                        _b[State.UserRole.Admin] = [],
                        _b[State.UserRole.Merchandiser] = [],
                        _b);
                    if (isEnrichAIEnabled) {
                        routeAllowList[State.UserRole.Admin].push(enrichDrawerRoutes.ai);
                        routeAllowList[State.UserRole.Merchandiser].push(enrichDrawerRoutes.ai);
                    }
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.identity.getMyIdentity()];
                case 2:
                    _a = _e.sent(), roles = _a.roles, licensed = _a.licensed;
                    role = getRoleFromRoles(roles);
                    if (licensed) {
                        (_c = routeAllowList[State.UserRole.Admin]).unshift.apply(_c, [enrichDrawerRoutes.editor, enrichDrawerRoutes.viewer, enrichDrawerRoutes.matrix]);
                        routeAllowList[State.UserRole.Merchandiser].unshift(enrichDrawerRoutes.viewer);
                    }
                    if (licensed || isEnrichAIEnabled) {
                        // currently only have one category and two routes
                        // as the enrich application grows, this logic will change
                        return [2 /*return*/, [
                                {
                                    label: 'MANAGE_PRODUCTS',
                                    routes: (_d = routeAllowList[role]) !== null && _d !== void 0 ? _d : [],
                                },
                            ]];
                    }
                    return [2 /*return*/, []];
                case 3:
                    e_1 = _e.sent();
                    return [2 /*return*/, []];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return { getDrawerRoutes: getDrawerRoutes, getUserRole: getUserRole };
};
exports.useUserRoutePermissions = useUserRoutePermissions;
