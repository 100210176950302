"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAllAttributeValuesForStrategyMatrix = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var gql_1 = require("../gql");
var attributes_utils_1 = require("../../../enrich-ai/client/attributes/hooks/attributes.utils");
var client_utils_1 = require("../../../enrich-ai/client/client.utils");
var useGetAllAttributeValuesForStrategyMatrix = function (_a) {
    var productTreeNodeId = _a.productTreeNodeId, catalog = _a.catalog, selectedAttribute = _a.selectedAttribute;
    var filter = (0, react_1.useMemo)(function () {
        var baseFilter = { label: { equals: selectedAttribute } };
        return (0, attributes_utils_1.getAggregatedAttributesFilter)(baseFilter, true);
    }, [selectedAttribute]);
    var _b = (0, client_1.useQuery)(gql_1.GET_ALL_ATTRIBUTE_VALUES_FOR_STRATEGY_MATRIX, {
        variables: {
            productTreeNodeId: productTreeNodeId,
            first: 2000,
            filter: filter,
            catalog: catalog,
        },
        skip: !productTreeNodeId || !selectedAttribute || !catalog,
    }), attributeValuesData = _b.data, attributeValuesError = _b.error, networkStatus = _b.networkStatus;
    return {
        attributeValuesData: attributeValuesData,
        attributeValuesIsLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        attributeValuesError: attributeValuesError,
    };
};
exports.useGetAllAttributeValuesForStrategyMatrix = useGetAllAttributeValuesForStrategyMatrix;
