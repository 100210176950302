"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogs = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../__generated__/graphql");
var get_catalogs_query_1 = require("./get-catalogs.query");
var client_constants_1 = require("../client.constants");
var useCatalogs = function (projectId) {
    var _a = (0, client_1.useQuery)(get_catalogs_query_1.GET_CATALOGS, {
        variables: {
            projectId: projectId,
            sort: [{ createdAt: graphql_1.OrderBy.Desc }],
            first: client_constants_1.PAGE_SIZE_EXTRA_LARGE,
        },
        skip: !projectId,
        fetchPolicy: 'cache-first',
    }), data = _a.data, rest = __rest(_a, ["data"]);
    var catalogs = (0, react_1.useMemo)(function () { var _a, _b, _c, _d, _e; return (_e = (_d = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.projects) === null || _a === void 0 ? void 0 : _a.edges[0]) === null || _b === void 0 ? void 0 : _b.node) === null || _c === void 0 ? void 0 : _c.catalogs) === null || _d === void 0 ? void 0 : _d.edges.map(function (_a) {
        var node = _a.node;
        return node;
    })) !== null && _e !== void 0 ? _e : []; }, [data]);
    return __assign(__assign({}, rest), { catalogs: catalogs });
};
exports.useCatalogs = useCatalogs;
