"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProducts = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var utils_1 = require("./utils");
var attributes_1 = require("../../attributes");
var client_utils_1 = require("../../client.utils");
var pagination_1 = require("../../hooks/pagination");
var get_products_query_1 = require("../get-products.query");
var hooks_1 = require("../../../hooks");
var store_1 = require("../../../../store");
var defaultSort = [{ product: { lastAttributeUpdatedAt: graphql_1.OrderBy.Desc } }];
var useGetProducts = function (_a) {
    var _b, _c, _d, _e;
    var attributeNode = _a.attributeNode, options = _a.options, search = _a.search, onCompleted = _a.onCompleted, productLabelFilter = _a.productLabelFilter, productMeasureFilter = _a.productMeasureFilter, _f = _a.skipMeasures, skipMeasures = _f === void 0 ? false : _f;
    var productTreeRootNodeId = (0, store_1.useProductTreeRootNodeId)();
    var productTreeId = (0, store_1.useProductTreeId)();
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var selectedProductTreeNode = (0, hooks_1.useSelectedTreeNode)();
    var _g = selectedProductTreeNode !== null && selectedProductTreeNode !== void 0 ? selectedProductTreeNode : {}, selectedStrategyNodeId = _g.id, descendants = _g.descendants;
    var isTerminalNode = (descendants === null || descendants === void 0 ? void 0 : descendants.totalCount) === 1;
    var isRootNode = selectedStrategyNodeId === productTreeRootNodeId;
    var _h = __read((0, pagination_1.usePageSize)(), 2), pageSize = _h[0], setPageSize = _h[1];
    var filter = (0, react_1.useMemo)(function () {
        var value = (attributeNode !== null && attributeNode !== void 0 ? attributeNode : {}).value;
        // Temporary solution. Remove this when BE data model is changed (status field is required)
        var presentationAttributesFilter = isTerminalNode && attributeNode && value === attributes_1.SpecialAttributeValue.Unknown
            && (0, utils_1.createPresentationAttributesFilterFromSelectedAttributeValue)(attributeNode);
        var productAttributeFilter = !presentationAttributesFilter && attributeNode && (0, utils_1.createFilterFromAttributeNode)(attributeNode);
        if (!search && !presentationAttributesFilter && !productAttributeFilter) {
            return null;
        }
        return __assign(__assign({}, presentationAttributesFilter && { attributes: presentationAttributesFilter }), (productAttributeFilter || search) && {
            product: __assign(__assign({}, productAttributeFilter && { attributes: productAttributeFilter }), search && { search: search }),
        });
    }, [attributeNode, isTerminalNode, search]);
    var _j = (0, client_1.useQuery)(get_products_query_1.GET_PRODUCT_TREE_NODE_PRODUCT_PRESENTATIONS, {
        skip: !catalogId || !selectedStrategyNodeId || (options === null || options === void 0 ? void 0 : options.skip),
        variables: __assign(__assign({ catalogId: catalogId, id: selectedStrategyNodeId }, filter && { productPresentationsFilter: filter }), { productLabelFilter: productLabelFilter, productPresentationLabelFilter: productLabelFilter, productMeasureFilter: productMeasureFilter, first: pageSize, before: null, after: null, last: null, skipMeasures: skipMeasures, 
            // TODO: remove this after the BE model changes
            skipCurrentPresentationLabels: !!isRootNode, skipGlobalPresentationLabels: !isRootNode, sort: defaultSort, productTreeId: productTreeId }),
        onCompleted: onCompleted,
    }), data = _j.data, variables = _j.variables, previousData = _j.previousData, loading = _j.loading, error = _j.error, networkStatus = _j.networkStatus, fetchMore = _j.fetchMore;
    var productPresentations = (0, react_1.useMemo)(function () { var _a; return (_a = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _a === void 0 ? void 0 : _a.productPresentations; }, [data]);
    var _k = ((_c = (_b = previousData === null || previousData === void 0 ? void 0 : previousData.productTreeNode) === null || _b === void 0 ? void 0 : _b.productPresentations) !== null && _c !== void 0 ? _c : {}).totalCount, prevTotalCount = _k === void 0 ? 0 : _k;
    var _l = productPresentations !== null && productPresentations !== void 0 ? productPresentations : {}, _m = _l.totalCount, totalCount = _m === void 0 ? 0 : _m, pageInfo = _l.pageInfo, _o = _l.edges, edges = _o === void 0 ? [] : _o;
    var pagination = (0, pagination_1.usePagination)({
        pageInfo: pageInfo,
        totalCount: totalCount,
        networkStatus: networkStatus,
        pageSize: pageSize,
        fetchMore: fetchMore,
        lastVariables: variables,
    });
    var products = (0, react_1.useMemo)(function () { return edges.map(function (_a) {
        var _b, _c, _d, _e, _f;
        var node = _a.node;
        var product = node.product, currentPresentationLabels = node.labels;
        var edges = product.labels.edges, presentations = product.presentations;
        var productPresentationLabels = (_f = (_e = (isRootNode ? (_d = (_c = (_b = presentations === null || presentations === void 0 ? void 0 : presentations.edges) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.node) === null || _d === void 0 ? void 0 : _d.labels : currentPresentationLabels)) === null || _e === void 0 ? void 0 : _e.edges) !== null && _f !== void 0 ? _f : [];
        var presentationLabelsMap = new Map(productPresentationLabels.map(function (_a) {
            var _b = _a.node, label = _b.label, validValues = _b.validValues;
            return [label, validValues];
        }));
        if (!presentationLabelsMap.size) {
            return product;
        }
        return __assign(__assign({}, product), { labels: {
                edges: edges.filter(function (_a) {
                    var _b;
                    var _c = _a.node, label = _c.label, assertedIncorrect = _c.assertedIncorrect, value = _c.value;
                    return !assertedIncorrect && !!((_b = presentationLabelsMap.get(label)) === null || _b === void 0 ? void 0 : _b.includes(value));
                }),
            } });
    }); }, [edges, isRootNode]);
    return {
        pagination: __assign(__assign({}, pagination), { totalCount: loading ? totalCount || prevTotalCount : totalCount, pageSize: pageSize, onPageSizeChange: setPageSize }),
        isLoading: (0, client_utils_1.isQueryLoading)(networkStatus),
        error: error,
        nodeTitle: ((_e = (_d = (data !== null && data !== void 0 ? data : previousData)) === null || _d === void 0 ? void 0 : _d.productTreeNode) === null || _e === void 0 ? void 0 : _e.title) || '',
        products: products,
    };
};
exports.useGetProducts = useGetProducts;
