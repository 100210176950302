"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductValuesContainer = void 0;
var react_1 = __importStar(require("react"));
var predicate_language_1 = require("@groupby/predicate-language");
var ui_components_1 = require("@groupby/ui-components");
var debounce_1 = require("debounce");
var product_values_selector_styles_1 = require("./product-values-selector.styles");
var hooks_1 = require("../../../../../../hooks");
var localization_1 = require("../../../../../../localization");
var ProductValuesContainer = function (_a) {
    var value = _a.value, onOptionChange = _a.onOptionChange;
    var _b = __read((0, react_1.useState)([]), 2), suggestions = _b[0], setSuggestions = _b[1];
    var client = (0, hooks_1.useEnrichClient)();
    var addError = (0, hooks_1.useNotification)().addError;
    var _c = __read((0, react_1.useState)(''), 2), inputValue = _c[0], setInputValue = _c[1];
    var t = (0, localization_1.useLocalization)().formatMessage;
    var token = (0, hooks_1.useToken)();
    var handleUpdateAutocompleteSuggestions = (0, debounce_1.debounce)(function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        var values, options, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, client.formalAttributeValues.getMany({
                            filter: new predicate_language_1.PredicateBuilder((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["name"], ["name"]))), '=~', new RegExp("^".concat(inputValue, ".*"), 'ig'))),
                        })];
                case 1:
                    values = _a.sent();
                    options = values === null || values === void 0 ? void 0 : values.reduce(function (transformedOptions, _a) {
                        var id = _a.id, name = _a.name;
                        var existingItem = transformedOptions.find(function (item) { return item.label === name; });
                        if (existingItem) {
                            var existingIds = JSON.parse(existingItem.value);
                            var idsArray = existingIds.push(id);
                            existingItem.value = JSON.stringify(idsArray);
                        }
                        else {
                            transformedOptions.push({ label: name, value: JSON.stringify([id]) });
                        }
                        return transformedOptions;
                    }, []);
                    setSuggestions(options);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    addError(t({ key: 'ADVANCED_FILTER_VLAUE_LOADING_ERROR' }));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, 300);
    var getSelectionLabel = function (selected) { var _a; return (_a = suggestions === null || suggestions === void 0 ? void 0 : suggestions.find(function (option) { return option.value === selected; })) === null || _a === void 0 ? void 0 : _a.label; };
    var derivedInputValue = (0, react_1.useMemo)(function () { return inputValue || (value && getSelectionLabel(value)) || ''; }, [value, inputValue]);
    (0, react_1.useEffect)(function () {
        if (token) {
            void handleUpdateAutocompleteSuggestions(inputValue);
        }
    }, [derivedInputValue, token]);
    var handleInputChange = function (_e, newValue) {
        setInputValue(newValue);
    };
    var handleOnChange = function (_e, option) {
        onOptionChange(option === null || option === void 0 ? void 0 : option.value);
    };
    return (react_1.default.createElement(product_values_selector_styles_1.StyledProductAutocompleteSelector, { onInputChange: handleInputChange, InputProps: { placeholder: t({ key: 'VALUE' }) }, onChange: handleOnChange, options: suggestions, inputValue: derivedInputValue, value: value !== null && value !== void 0 ? value : '', variant: 'outlined', selectOnFocus: true, background: ui_components_1.baseColors.white }));
};
exports.ProductValuesContainer = ProductValuesContainer;
exports.default = exports.ProductValuesContainer;
var templateObject_1;
