"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeValuesSelector = void 0;
var react_1 = __importStar(require("react"));
var common_1 = require("../../../../../../common");
var feature_flags_1 = require("../../../../../../feature-flags");
var hooks_1 = require("../../../../../../hooks");
var localization_1 = require("../../../../../../localization");
var attributes_1 = require("../../../../../components/attributes");
var formal_attributes_1 = require("../../../../../formal-attributes");
var store_1 = require("../../../../../../store");
var AttributeValuesSelector = function (_a) {
    var onOptionChange = _a.onOptionChange, selected = _a.selected, filter = _a.filter;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var isMultiFacetNavEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.MultiFacetNav);
    var attributeValuesMap = (0, store_1.useAttributeValuesMap)(isMultiFacetNavEnabled);
    var _b = (0, formal_attributes_1.useFormalAttributes)(), getAttributeName = _b.getAttributeName, getAttributeValueName = _b.getAttributeValueName;
    var selectedAttributeValues = (!isMultiFacetNavEnabled
        ? attributeValuesMap.get(filter.fieldSpecifier)
        : attributeValuesMap[filter.fieldSpecifier]);
    var options = (0, react_1.useMemo)(function () { return selectedAttributeValues === null || selectedAttributeValues === void 0 ? void 0 : selectedAttributeValues.map(function (attribute) { return ({
        label: (0, attributes_1.getAttributeValueTitle)({ subAttribute: attribute, getAttributeName: getAttributeName, getAttributeValueName: getAttributeValueName }),
        value: JSON.stringify({ id: attribute.valueId, name: attribute.valueName }),
    }); }); }, [selectedAttributeValues, getAttributeName, getAttributeValueName]);
    return (react_1.default.createElement(common_1.AutocompleteSelector, { options: options !== null && options !== void 0 ? options : [], onChange: onOptionChange, selected: selected, placeholder: t({ key: 'VALUE' }) }));
};
exports.AttributeValuesSelector = AttributeValuesSelector;
exports.default = exports.AttributeValuesSelector;
