"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogStatistics = void 0;
var client_1 = require("@apollo/client");
var date_fns_1 = require("date-fns");
var get_catalog_data_for_dashboard_query_1 = require("./get-catalog-data-for-dashboard.query");
var client_utils_1 = require("../client.utils");
var localization_1 = require("../../../localization");
var useCatalogStatistics = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var catalogId = _a.catalogId, productTreeId = _a.productTreeId, productTreeNodeId = _a.productTreeNodeId;
    var shouldSkip = !catalogId || !productTreeId;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _l = (0, client_1.useQuery)(get_catalog_data_for_dashboard_query_1.GET_CATALOG_DATA_FOR_DASHBOARD, {
        variables: {
            ids: [catalogId],
            productTreeId: productTreeId,
            productTreeNodeId: productTreeNodeId,
        },
        skip: shouldSkip,
    }), networkStatus = _l.networkStatus, data = _l.data, error = _l.error;
    var catalogNode = (_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.catalogs) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.node;
    var closedTickets = catalogNode === null || catalogNode === void 0 ? void 0 : catalogNode.closedTickets;
    var statistics = catalogNode === null || catalogNode === void 0 ? void 0 : catalogNode.statistics;
    var products = catalogNode === null || catalogNode === void 0 ? void 0 : catalogNode.products;
    var attributionsSummary = (_f = (_e = catalogNode === null || catalogNode === void 0 ? void 0 : catalogNode.statistics) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.totals;
    var normalizedAttributeBreakdown = (_g = attributionsSummary === null || attributionsSummary === void 0 ? void 0 : attributionsSummary.inferenceAttributes) !== null && _g !== void 0 ? _g : 0;
    var augmentedAttributeBreakdown = (_h = attributionsSummary === null || attributionsSummary === void 0 ? void 0 : attributionsSummary.derivationAttributes) !== null && _h !== void 0 ? _h : 0;
    var totalAttributeBreakdown = normalizedAttributeBreakdown + augmentedAttributeBreakdown;
    var normalizedAttributionBreakdown = (_j = attributionsSummary === null || attributionsSummary === void 0 ? void 0 : attributionsSummary.inferenceValues) !== null && _j !== void 0 ? _j : 0;
    var augmentedAttributionBreakdown = (_k = attributionsSummary === null || attributionsSummary === void 0 ? void 0 : attributionsSummary.derivationValues) !== null && _k !== void 0 ? _k : 0;
    var totalAttributionBreakdown = normalizedAttributionBreakdown + augmentedAttributionBreakdown;
    var getLastUpdatedDate = function () {
        var _a;
        if (!((_a = closedTickets === null || closedTickets === void 0 ? void 0 : closedTickets.edges) === null || _a === void 0 ? void 0 : _a.length))
            return t({ key: 'NOT_APPLICABLE_SHORT' });
        var firstTicket = closedTickets.edges[0].node.ticket;
        return (0, date_fns_1.format)((0, date_fns_1.parseISO)(firstTicket.closedAt), 'MMM dd, yyyy');
    };
    var lastUpdated = getLastUpdatedDate();
    var isEmpty = !!error;
    var isLoading = (0, client_utils_1.isQueryLoading)(networkStatus);
    return {
        normalizedAttributeBreakdown: normalizedAttributeBreakdown,
        augmentedAttributeBreakdown: augmentedAttributeBreakdown,
        totalAttributeBreakdown: totalAttributeBreakdown,
        normalizedAttributionBreakdown: normalizedAttributionBreakdown,
        augmentedAttributionBreakdown: augmentedAttributionBreakdown,
        totalAttributionBreakdown: totalAttributionBreakdown,
        attributionsSummary: attributionsSummary,
        closedTickets: closedTickets,
        products: products,
        lastUpdated: lastUpdated,
        error: error,
        isEmpty: isEmpty,
        isLoading: isLoading,
        statistics: statistics,
    };
};
exports.useCatalogStatistics = useCatalogStatistics;
exports.default = exports.useCatalogStatistics;
