"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsPreviewContainer = void 0;
var react_1 = __importStar(require("react"));
var products_action_panel_1 = require("./products-action-panel");
var products_list_view_1 = require("./products-list-view");
var products_preview_component_1 = require("./products-preview.component");
var products_preview_styles_1 = require("./products-preview.styles");
var product_details_dialog_component_1 = require("../../product-details-dialog/product-details-dialog.component");
var bulk_edit_product_attributes_dialog_1 = require("../bulk-edit-product-attributes-dialog");
var hooks_1 = require("../hooks");
var reclassify_products_dialog_component_1 = require("../reclassify-products-dialog/reclassify-products-dialog.component");
var utils_1 = require("../../../client/proposal/hooks/utils");
var hooks_2 = require("../../../hooks");
var store_1 = require("../../../../store");
var ProductsPreviewContainer = function (_a) {
    var _b;
    var isListViewMode = _a.isListViewMode, productsActionPanelProps = __rest(_a, ["isListViewMode"]);
    var selectedAttributeNode = (0, store_1.useSelectedAttributeNode)();
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var searchTerm = (0, store_1.useSearch)();
    var selectedProductTreeNode = (0, hooks_2.useSelectedTreeNode)();
    var isReclassifyEnabled = ((_b = selectedProductTreeNode === null || selectedProductTreeNode === void 0 ? void 0 : selectedProductTreeNode.descendants) === null || _b === void 0 ? void 0 : _b.totalCount) === 1;
    var _c = __read((0, react_1.useState)(false), 2), reclassifyDialogOpened = _c[0], setReclassifyDialogOpened = _c[1];
    var _d = __read((0, react_1.useState)(null), 2), bulkActionsFilter = _d[0], setBulkActionsFilter = _d[1];
    var _e = __read((0, react_1.useState)(), 2), activeProductId = _e[0], setActiveProductId = _e[1];
    var _f = __read((0, react_1.useState)(false), 2), isCustomizeViewOpen = _f[0], setIsCustomizeViewOpen = _f[1];
    var _g = __read((0, react_1.useState)(false), 2), isBulkEditDialogOpen = _g[0], setIsBulkEditDialogOpen = _g[1];
    var _h = __read((0, react_1.useState)(false), 2), singleReclassify = _h[0], setSingleReclassify = _h[1];
    var _j = selectedProductTreeNode !== null && selectedProductTreeNode !== void 0 ? selectedProductTreeNode : {}, selectedStrategyNodeId = _j.id, descendants = _j.descendants;
    var isTerminalNode = (descendants === null || descendants === void 0 ? void 0 : descendants.totalCount) === 1;
    var _k = (0, hooks_1.useProducts)(), products = _k.products, pagination = _k.pagination, totalCount = _k.pagination.totalCount, areProductsLoading = _k.isLoading, productsLoadingError = _k.error;
    var _l = (0, hooks_1.useProductsSelection)({
        products: products,
        productsPagination: pagination,
        areProductsLoading: areProductsLoading,
    }), excludedProductIds = _l.excludedProductIds, selectAllMode = _l.selectAllMode, isProductSelected = _l.isProductSelected, selectProduct = _l.selectProduct, areAllProductsSelected = _l.areAllProductsSelected, onSelectAllProducts = _l.onSelectAllProducts, resetSelection = _l.resetSelection, currentPageSelectedProductIds = _l.currentPageSelectedProductIds, selectedProductsCount = _l.selectedProductsCount, isAtLeastOneProductSelected = _l.isAtLeastOneProductSelected, isCurrentPageSelected = _l.isCurrentPageSelected, selectCurrentPage = _l.selectCurrentPage;
    var getBulkActionsFilter = function () {
        var newFilterValue = (0, utils_1.getOptimizedFilter)({
            catalogId: catalogId,
            productTreeNode: selectedProductTreeNode,
            attributeNode: selectedAttributeNode,
            selectAllMode: selectAllMode,
            selectedProductIds: excludedProductIds,
            search: searchTerm,
            isTerminalNode: isTerminalNode,
        });
        setBulkActionsFilter(newFilterValue);
    };
    var handleBulkReclassify = function () {
        getBulkActionsFilter();
        setReclassifyDialogOpened(true);
    };
    var handleBulkEdit = function () {
        getBulkActionsFilter();
        setIsBulkEditDialogOpen(true);
    };
    var handleSingleReclassify = function (id) {
        setSingleReclassify(true);
        setBulkActionsFilter({
            id: { in: [id] },
        });
        setReclassifyDialogOpened(true);
    };
    var handleReclassifySuccess = function () {
        setSingleReclassify(false);
        setReclassifyDialogOpened(false);
        resetSelection();
    };
    var commonProps = {
        products: products,
        pagination: pagination,
        areProductsLoading: areProductsLoading,
        productsLoadingError: productsLoadingError,
        excludedProductIds: excludedProductIds,
        selectAllMode: selectAllMode,
        areAllProductsSelected: areAllProductsSelected,
        onSelectAllProducts: onSelectAllProducts,
        currentPageSelectedProductIds: currentPageSelectedProductIds,
        isAtLeastOneProductSelected: isAtLeastOneProductSelected,
        selectProduct: selectProduct,
        isProductSelected: isProductSelected,
        isCurrentPageSelected: isCurrentPageSelected,
        selectCurrentPage: selectCurrentPage,
        resetSelection: resetSelection,
        selectedProductsCount: selectedProductsCount,
        activeProductId: activeProductId,
        isCustomizeViewOpen: isCustomizeViewOpen,
        onActiveProductIdChange: setActiveProductId,
        onCustomizeViewClose: function () { return setIsCustomizeViewOpen(false); },
    };
    var activeProductIds = products.findIndex(function (product) { return product.id === activeProductId; });
    var handleNextClick = function () {
        if (activeProductIds < products.length - 1) {
            setActiveProductId(products[activeProductIds + 1].id);
        }
        else {
            setActiveProductId(products[0].id);
        }
    };
    var handlePreviousClick = function () {
        if (activeProductIds > 0) {
            setActiveProductId(products[activeProductIds - 1].id);
        }
        else {
            setActiveProductId(products[products.length - 1].id);
        }
    };
    return (react_1.default.createElement(products_preview_styles_1.StyledProductPreviewSectionContainer, null,
        react_1.default.createElement(products_action_panel_1.ProductsActionPanel, __assign({}, productsActionPanelProps, { isListViewMode: isListViewMode, totalCount: totalCount, isAtLeastOneProductSelected: isAtLeastOneProductSelected, onSelectCurrentPage: selectCurrentPage, selectedItemsCount: selectedProductsCount, onReclassify: handleBulkReclassify, onEdit: handleBulkEdit, onSettingsClick: function () { return setIsCustomizeViewOpen(true); }, disableReclassification: !isReclassifyEnabled, onResetSelectedProducts: resetSelection, isCurrentPageSelected: isCurrentPageSelected, onSelectAllProducts: onSelectAllProducts, areAllProductsSelected: areAllProductsSelected })),
        reclassifyDialogOpened && (react_1.default.createElement(reclassify_products_dialog_component_1.ReclassifyProductsDialog, { onClose: function () {
                setReclassifyDialogOpened(false);
                setSingleReclassify(false);
            }, onSuccess: handleReclassifySuccess, productFilter: bulkActionsFilter, selectedStrategyNodeId: selectedStrategyNodeId, selectedItemsCount: singleReclassify ? 1 : selectedProductsCount })),
        isBulkEditDialogOpen && (react_1.default.createElement(bulk_edit_product_attributes_dialog_1.BulkEditProductAttributesDialog, { productsFilter: bulkActionsFilter, onSubmit: function () {
                resetSelection();
                setIsBulkEditDialogOpen(false);
            }, onClose: function () { return setIsBulkEditDialogOpen(false); } })),
        isListViewMode
            ? react_1.default.createElement(products_list_view_1.ProductsListView, __assign({}, commonProps))
            : react_1.default.createElement(products_preview_component_1.ProductsPreview, __assign({}, commonProps)),
        !!activeProductId && (react_1.default.createElement(product_details_dialog_component_1.ProductDetailsDialog, { selectedProductId: activeProductId, onClose: function () { return setActiveProductId(undefined); }, onNext: handleNextClick, onPrevious: handlePreviousClick, onReclassify: function () {
                handleSingleReclassify(activeProductId);
                setActiveProductId(undefined);
            } }))));
};
exports.ProductsPreviewContainer = ProductsPreviewContainer;
exports.default = exports.ProductsPreviewContainer;
