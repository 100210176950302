"use strict";
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAggregatedValuesProductLabelsFilter = exports.sortingItemToPropName = exports.sortingItemToLocalSortMap = exports.sortingItemToAggregatedValuesSortMap = exports.sortingItemToStrategyValuesSortMap = exports.sortingItemToProductValuesSortMap = void 0;
var graphql_1 = require("../../../__generated__/graphql");
var models_1 = require("../../models");
var utils_1 = require("../../utils");
exports.sortingItemToProductValuesSortMap = (_a = {},
    _a[models_1.SortingItem.SortByTextAsc] = [{ value: graphql_1.OrderBy.Asc }],
    _a[models_1.SortingItem.SortByTextDesc] = [{ value: graphql_1.OrderBy.Desc }],
    _a[models_1.SortingItem.SortByProductCountsAsc] = [{ productCount: graphql_1.OrderBy.Asc }],
    _a[models_1.SortingItem.SortByProductCountsDesc] = [{ productCount: graphql_1.OrderBy.Desc }],
    _a);
exports.sortingItemToStrategyValuesSortMap = (_b = {},
    _b[models_1.SortingItem.SortByTextAsc] = [{ value: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByTextDesc] = [{ value: graphql_1.OrderBy.Desc }],
    _b[models_1.SortingItem.SortByProductCountsAsc] = [{ value: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByProductCountsDesc] = [{ value: graphql_1.OrderBy.Asc }],
    _b);
exports.sortingItemToAggregatedValuesSortMap = (_c = {},
    _c[models_1.SortingItem.SortByTextAsc] = { value: graphql_1.SortOrder.Asc },
    _c[models_1.SortingItem.SortByTextDesc] = { value: graphql_1.SortOrder.Desc },
    _c[models_1.SortingItem.SortByProductCountsAsc] = { productCount: graphql_1.SortOrder.Asc },
    _c[models_1.SortingItem.SortByProductCountsDesc] = { productCount: graphql_1.SortOrder.Desc },
    _c);
exports.sortingItemToLocalSortMap = (_d = {},
    _d[models_1.SortingItem.SortByTextAsc] = (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Ascend),
    _d[models_1.SortingItem.SortByTextDesc] = (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Descend),
    _d[models_1.SortingItem.SortByProductCountsAsc] = (0, utils_1.sortByNumberField)(utils_1.SortOrder.Ascend),
    _d[models_1.SortingItem.SortByProductCountsDesc] = (0, utils_1.sortByNumberField)(utils_1.SortOrder.Descend),
    _d);
exports.sortingItemToPropName = (_e = {},
    _e[models_1.SortingItem.SortByTextAsc] = 'value',
    _e[models_1.SortingItem.SortByTextDesc] = 'value',
    _e[models_1.SortingItem.SortByProductCountsAsc] = 'productCount',
    _e[models_1.SortingItem.SortByProductCountsDesc] = 'productCount',
    _e);
var getAggregatedValuesProductLabelsFilter = function (attributeName, options) {
    var _a = (options !== null && options !== void 0 ? options : {}).includeOnlyValidValues, includeOnlyValidValues = _a === void 0 ? false : _a;
    return {
        label: {
            equals: attributeName,
        },
        isRequirement: includeOnlyValidValues,
        includeNA: true,
    };
};
exports.getAggregatedValuesProductLabelsFilter = getAggregatedValuesProductLabelsFilter;
