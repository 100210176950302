"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOptimizedFilter = void 0;
var attributes_1 = require("../../attributes");
var utils_1 = require("../../product/hooks/utils");
var getOptimizedFilter = function (_a) {
    var _b;
    var catalogId = _a.catalogId, productTreeNode = _a.productTreeNode, attributeNode = _a.attributeNode, selectAllMode = _a.selectAllMode, selectedProductIds = _a.selectedProductIds, search = _a.search, isTerminalNode = _a.isTerminalNode;
    // When we have a defined set of products to reclassify we don't need any other filters
    if (!selectAllMode && selectedProductIds.length) {
        return {
            id: { in: selectedProductIds },
        };
    }
    var filter = {};
    if (attributeNode) {
        var value = attributeNode.value;
        // Temporary solution. Remove this when BE data model is changed (status field is required)
        var presentationAttributesFilter = isTerminalNode && value === attributes_1.SpecialAttributeValue.Unknown
            && (0, utils_1.createProposalPresentationAttributesFilter)(attributeNode);
        if (presentationAttributesFilter) {
            filter.presentations = presentationAttributesFilter;
        }
        else {
            filter.attributes = (0, utils_1.createFilterFromAttributeNode)(attributeNode);
        }
    }
    if (selectAllMode && selectedProductIds.length) {
        filter.id = { notIn: selectedProductIds };
    }
    if (productTreeNode && productTreeNode.title !== attributes_1.ONTOLOGY_NODE_TITLE) {
        if (search) {
            filter.search = search;
        }
        filter.presentations = __assign(__assign({}, filter.presentations), { some: __assign(__assign({}, (_b = filter.presentations) === null || _b === void 0 ? void 0 : _b.some), { ancestralCatalogId: {
                    in: [catalogId],
                }, productTreeNode: {
                    id: {
                        in: [productTreeNode.id],
                    },
                } }) });
    }
    return filter;
};
exports.getOptimizedFilter = getOptimizedFilter;
