"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyAttributesContainer = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var strategy_attributes_component_1 = require("./strategy-attributes.component");
var strategy_attributes_styles_1 = require("./strategy-attributes.styles");
var attributes_1 = require("../../client/attributes");
var components_1 = require("../../components");
var confirmation_alert_1 = require("../../components/confirmation-alert");
var context_1 = require("../../context");
var generic_1 = require("../../../generic");
var localization_1 = require("../../../localization");
var store_1 = require("../../../store");
var StrategyAttributesContainer = function (_a) {
    var onReEnrich = _a.onReEnrich;
    var dispatch = (0, react_redux_1.useDispatch)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var sortingItem = (0, store_1.useAttributesSort)();
    var setSortingItem = (0, react_1.useCallback)(function (sortingItem) { return dispatch((0, store_1.enrichAISetAttributesSort)(sortingItem)); }, [dispatch]);
    var _b = (0, attributes_1.useGetAllAggregatedAttributes)({
        catalogId: selectedCatalogId,
        productTreeNodeId: selectedStrategyNodeId,
        sortingItem: sortingItem,
    }), attributes = _b.data, isLoading = _b.isLoading, isRefetching = _b.isRefetching, _c = _b.pagination, isLoadingMore = _c.isLoadingMore, hasNextPage = _c.hasNextPage, onNextPage = _c.onNextPage, error = _b.error;
    if (isLoading && !isRefetching) {
        return react_1.default.createElement(components_1.PreviewSkeleton, { contrast: true });
    }
    if (error) {
        return react_1.default.createElement(strategy_attributes_styles_1.StyledErrorAlert, { severity: "error" }, error.message);
    }
    return (react_1.default.createElement(context_1.CheckedAttributesProvider, null,
        react_1.default.createElement(confirmation_alert_1.ConfirmationAlertProvider, null,
            react_1.default.createElement(strategy_attributes_component_1.StrategyAttributes, { sortingItem: sortingItem, moreItemsLoading: isLoadingMore, hasNextPage: hasNextPage, onLoadMore: onNextPage, attributes: attributes, noDataFallback: !attributes.length && react_1.default.createElement(generic_1.NoDataFallback, { title: t({ key: 'NO_DATA_AVAILABLE' }) }), onSort: setSortingItem, onReEnrich: onReEnrich }))));
};
exports.StrategyAttributesContainer = StrategyAttributesContainer;
exports.default = exports.StrategyAttributesContainer;
