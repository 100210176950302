"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTextAttributeAggregatedValues = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var ramda_1 = require("ramda");
var attributes_1 = require("../../attributes");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var hooks_1 = require("../../hooks");
var gql_1 = require("../gql");
var values_utils_1 = require("../values.utils");
// merge the new part with an already loaded
var updateQuery = function (previousResult, options) {
    var _a, _b;
    var _c = ((_a = previousResult.productTreeNode.productAggregatedLabelsNew) !== null && _a !== void 0 ? _a : {}).edges, prevAttributes = _c === void 0 ? [] : _c;
    var productTreeNode = ((_b = options === null || options === void 0 ? void 0 : options.fetchMoreResult) !== null && _b !== void 0 ? _b : {}).productTreeNode;
    var _d = productTreeNode.productAggregatedLabelsNew, _e = _d.edges, edges = _e === void 0 ? [] : _e, productAggregatedLabelsNew = __rest(_d, ["edges"]);
    if (!edges.length) {
        return {
            productTreeNode: __assign(__assign({}, previousResult.productTreeNode), productTreeNode),
        };
    }
    var newAttribute = edges[0].node;
    var changedAttributeIndex = prevAttributes.findIndex(function (_a) {
        var node = _a.node;
        return node.label === newAttribute.label;
    });
    var prevAttribute = prevAttributes[changedAttributeIndex];
    var changedAttribute = {
        node: __assign(__assign({}, prevAttribute.node), { values: __assign(__assign({}, newAttribute.values), { edges: __spreadArray(__spreadArray([], __read(prevAttribute.node.values.edges), false), __read(newAttribute.values.edges), false) }) }),
    };
    var newAttributes = (0, ramda_1.update)(changedAttributeIndex, changedAttribute, prevAttributes);
    return {
        productTreeNode: __assign(__assign({}, productTreeNode), { productAggregatedLabelsNew: __assign(__assign({}, productAggregatedLabelsNew), { edges: newAttributes }) }),
    };
};
var fetchMoreOptions = { updateQuery: updateQuery };
/**
 *
 * @returns Aggregated values associated with products for a specific text attribute,
 * covering the selected node and all its children.
 *
 * Behavior controlled by the `isRequirement` filter flag:
 * - If disabled (default behavior): Only values associated with products (`productCount > 0`) are included.
 * - If enabled: Includes "In Strategy" values that are not associated with any products (`productCount = 0`),
 *   but excludes values associated with products if they are not "In Strategy."
 *   (Valid product values + rest "In Strategy" values)
 */
var useGetTextAttributeAggregatedValues = function (_a) {
    var _b, _c;
    var catalogId = _a.catalogId, productTreeNodeId = _a.productTreeNodeId, attributeName = _a.attributeName, sortingItem = _a.sortingItem, includeNA = _a.includeNA, _d = _a.pageSize, pageSize = _d === void 0 ? client_constants_1.PAGE_SIZE_LARGE : _d, options = _a.options;
    var sort = values_utils_1.sortingItemToAggregatedValuesSortMap[sortingItem];
    var productAttributesFilter = (0, react_1.useMemo)(function () { return (0, values_utils_1.getAggregatedValuesProductLabelsFilter)(attributeName, { includeOnlyValidValues: true }); }, [attributeName]);
    var _e = __read((0, client_1.useLazyQuery)(gql_1.GET_AGGREGATED_TEXT_ATTRIBUTE_VALUES, __assign({ notifyOnNetworkStatusChange: true, variables: {
            sort: sort,
            productTreeNodeId: productTreeNodeId !== null && productTreeNodeId !== void 0 ? productTreeNodeId : '',
            catalogId: catalogId,
            filter: productAttributesFilter,
            includeNA: includeNA,
            first: pageSize,
            last: null,
            before: null,
            after: null,
        }, fetchPolicy: 'cache-first' }, options)), 2), getProductAggregateValues = _e[0], _f = _e[1], data = _f.data, error = _f.error, networkStatus = _f.networkStatus, variables = _f.variables, fetchMore = _f.fetchMore;
    // using useQuery skip: true will remove all previously uploaded data
    (0, react_1.useEffect)(function () {
        if (attributeName && !(options === null || options === void 0 ? void 0 : options.skip)) {
            void getProductAggregateValues({ variables: { catalogId: catalogId, productTreeNodeId: productTreeNodeId, filter: productAttributesFilter } });
        }
    }, [productAttributesFilter, catalogId, getProductAggregateValues, options === null || options === void 0 ? void 0 : options.skip, productTreeNodeId, attributeName]);
    var _g = (_b = data === null || data === void 0 ? void 0 : data.productTreeNode) !== null && _b !== void 0 ? _b : {}, productPresentations = _g.productPresentations, productAggregatedLabelsNew = _g.productAggregatedLabelsNew;
    var selectedAttribute = (0, react_1.useMemo)(function () { var _a; return (_a = productAggregatedLabelsNew === null || productAggregatedLabelsNew === void 0 ? void 0 : productAggregatedLabelsNew.edges[0]) === null || _a === void 0 ? void 0 : _a.node; }, [productAggregatedLabelsNew]);
    var pageInfo = ((_c = selectedAttribute === null || selectedAttribute === void 0 ? void 0 : selectedAttribute.values) !== null && _c !== void 0 ? _c : {}).pageInfo;
    var values = (0, react_1.useMemo)(function () {
        var _a, _b, _c, _d;
        if (!data) {
            return [];
        }
        var valuesData = (_b = (_a = selectedAttribute === null || selectedAttribute === void 0 ? void 0 : selectedAttribute.values) === null || _a === void 0 ? void 0 : _a.edges) !== null && _b !== void 0 ? _b : [];
        var nodeProductTotalCount = (_c = productPresentations === null || productPresentations === void 0 ? void 0 : productPresentations.totalCount) !== null && _c !== void 0 ? _c : 0;
        var uniqueValuesMap = new Map();
        var notApplicableValue = { value: attributes_1.SpecialAttributeValue.NotApplicable, productCount: 0 };
        valuesData.forEach(function (_a) {
            var _b = _a.node, value = _b.value, origin = _b.origin, productCount = _b.productCount;
            var existingValue = value === attributes_1.SpecialAttributeValue.NotApplicable ? notApplicableValue : uniqueValuesMap.get(value);
            if (existingValue) {
                existingValue.productCount += productCount;
            }
            else {
                var attributeValue = {
                    value: value,
                    origin: origin,
                    productCount: productCount,
                    isValid: true,
                };
                uniqueValuesMap.set(value, attributeValue);
            }
        });
        var unknownValueProductCount = nodeProductTotalCount - ((_d = selectedAttribute === null || selectedAttribute === void 0 ? void 0 : selectedAttribute.productCount) !== null && _d !== void 0 ? _d : 0);
        var unknownValue = { value: attributes_1.SpecialAttributeValue.Unknown, productCount: unknownValueProductCount };
        var convertedValues = Array.from(uniqueValuesMap.values());
        var sortFunction = values_utils_1.sortingItemToLocalSortMap[sortingItem];
        var sortedValues = sortFunction(convertedValues, values_utils_1.sortingItemToPropName[sortingItem]);
        return sortedValues.concat([notApplicableValue, unknownValue]);
    }, [data, productPresentations, selectedAttribute, sortingItem]);
    var pagination = (0, hooks_1.usePagination)({
        pageInfo: pageInfo,
        networkStatus: networkStatus,
        pageSize: client_constants_1.PAGE_SIZE_LARGE,
        fetchMore: fetchMore,
        fetchMoreOptions: fetchMoreOptions,
        lastVariables: variables,
    });
    return {
        data: values,
        pagination: pagination,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        isRefetching: (0, client_utils_1.isQueryRefetching)(networkStatus),
        error: error,
        attributeProductCount: selectedAttribute === null || selectedAttribute === void 0 ? void 0 : selectedAttribute.productCount,
    };
};
exports.useGetTextAttributeAggregatedValues = useGetTextAttributeAggregatedValues;
